import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from '../services/core/local-storage.service';

@Component({
  selector: 'app-user-qr',
  templateUrl: './user-qr.page.html',
  styleUrls: ['./user-qr.page.scss'],
})
export class UserQrPage implements OnInit {
qr=''
  constructor(
    private localStorageService:LocalStorageService,
    private  modalController: ModalController,

  ) { 
    this.qr=this.localStorageService.getUdId('UdId');
    console.log(this.qr)
  }

  ngOnInit() {

  }
  close(){
this.modalController.dismiss();
  }

}
