import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { GlobalVariable } from '../global';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { PickupTimePage } from '../models/pickup-time/pickup-time.page';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-order-type-selection',
  templateUrl: './order-type-selection.page.html',
  styleUrls: ['./order-type-selection.page.scss'],
})
export class OrderTypeSelectionPage implements OnInit {
orderType = 'pickup';
pickupTime;
day: any;
time: any;
date: any;

  constructor(public globals : GlobalVariable,
    private modalController: ModalController,
    public server: UserApiService,
    private router : Router,
    public navCtrl : NavController) { }

  ngOnInit() {
  }

  pickupOrder(){
    this.orderType = 'pickup';
    this.globals.OrderType = this.orderType;
    this.modalController.dismiss();
    this.router.navigate(['/search-user', { type: 'pickup' }]);
  }

  deliveryOrder(){
    this.orderType = 'delivery';
    this.globals.OrderType = this.orderType;
    this.modalController.dismiss();
    this.router.navigate(['/search-user', { type: 'delivery' }]);
  }

  orderHistory(){
    this.modalController.dismiss();
    this.router.navigate(['/recent-fav']);
  }

  async showPickupModel() {
    const modal = await this.modalController.create({
      component: PickupTimePage,
      cssClass: 'customize_pickupModel',
      mode:'ios',
      componentProps: { pickup: this.pickupTime },
    });
    modal.onDidDismiss().then((res) => {
      console.log(res);
      console.log("myDate.....",this.globals.myDate)
        if(this.globals.pickup_Time=='now'){
          this.pickupTime=undefined;
        }
        else{
          this.pickupTime = res.data.pickup;
         
          this.convertDate(this.pickupTime);
        }

    });
    return await modal.present();
  }

  convertDate(date) {
    this.server.date_convert(date).subscribe(
      (data) => {
        console.log(data);

        this.day = data.day;
        let parsed = Date.parse(date);
        let time = data.time;
        time = time.split('.');
        console.log(time);
        let hours = time[0];
        let mins = time[1];
        console.log(date, hours, mins);

        (this.time = this.tConvert(hours + ':' + mins)),
          (this.date = data.date);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  tConvert(time) {
    console.log(time, 'convrt time');
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM ' : ' PM '; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

}
