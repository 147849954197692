import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, Platform } from '@ionic/angular';

import { GlobalVariable } from '../../global';
import { GuestLoginPage } from '../../guest-login/guest-login.page';
import { UserApiService } from '../../services/user-api.service';
import { LocalStorageService } from 'src/app/services/core/local-storage.service';
import { UserQrPage } from 'src/app/user-qr/user-qr.page';

@Component({
  selector: 'app-navigation-model',
  templateUrl: './navigation-model.component.html',
  styleUrls: ['./navigation-model.component.scss'],
})
export class NavigationModelComponent implements OnInit {
  @Input() data: any;

  isLoggedIn: boolean = false;

  branchData:any;
  constructor(
    private router: Router,
    public server: UserApiService,
    public globals: GlobalVariable,
    private navCTRL: NavController,
    private modalController: ModalController,
    public modalCtrl: ModalController,
    public platform: Platform,
    private localStorageService: LocalStorageService
  ) {
    console.log('here in const');
  }

  ngOnInit() {
    console.log('here in oninit');
    let login = JSON.parse(this.localStorageService.getLogin('isLogin'));
   

    if (login) {
      this.globals.isloggedIn = login;
      this.isLoggedIn = true;
    }


    let Data = JSON.parse(this.localStorageService.getLogin('activeBranch'))
    console.log("Data Branch" , Data);
    if(Data){
      console.log("Data Branch" , Data);
      
      this.branchData = Data
    }
  }

  goTo(path) {
    console.log(this.isLoggedIn);
    if (this.isLoggedIn == true) {
      this.router.navigate([path]);
    } else {
      this.globals.loginmodal();
    }

    this.close()
  }

  redirect(path: any) {
    this.router.navigate([path]);
    this.close();
  }

  async openQRMODAL() {
    if (this.globals.isloggedIn == true) {
      console.log(this.globals.isloggedIn);
      const modal = await this.modalController.create({
        component: UserQrPage,
        componentProps: { data: {} },

        backdropDismiss: true,
        cssClass: 'QRModal',
        animated: true,
        swipeToClose: true,
        mode: 'ios',
        // handle: false,
      });

      await modal.present();
    } else {
      this.globals.loginmodal();
    }
  }
  // register() {
  //   if (this.data?.isFromCart) {
  //     this.globals.takeMeToCart = true;
  //   }
  //   this.modalCtrl.dismiss();

  //   // this.router.navigateByUrl('/sign-up');
  //   this.navCTRL.navigateRoot('/sign-up');
  // }
  // ionViewWillEnter() {
  //   console.log(this.data);
  // }
  // async guestLogin() {
  //   // let modal=await this.globals.presentToast("Coming soon");
  //   this.modalCtrl.dismiss();
  //   let modal = await this.modalCtrl.create({
  //     component: GuestLoginPage,
  //     mode: 'ios',
  //     cssClass: 'guestModal',
  //   });
  //   modal.present();
  // }
  // loginwithEmail() {
  //   if (this.data?.isFromCart) {
  //     this.globals.takeMeToCart = true;
  //   }
  //   this.navCTRL.navigateRoot('/login');

  //   this.modalCtrl.dismiss();
  // }
  // openPrivacy() {
  //   this.router.navigate(['privacy-policy']);
  //   this.modalCtrl.dismiss();

  //   // this.modal
  // }
  // terms() {
  //   this.router.navigate(['terms-conditions']);
  //   this.modalCtrl.dismiss();

  //   // this.modal
  // }
  close() {
    console.log('login modal');
    this.modalCtrl.dismiss({ dismiss: 'true' });
  }
}
