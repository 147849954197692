import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavController,
  ToastController,
} from '@ionic/angular';

import { ModalController } from '@ionic/angular';
import { CustomizeItemPage } from '../../app/models/customize-item/customize-item.page';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../app/global';
import { UserApiService } from '../services/user-api.service';
@Component({
  selector: 'app-item-preview',
  templateUrl: './item-preview.page.html',
  styleUrls: ['./item-preview.page.scss'],
})
export class ItemPreviewPage implements OnInit {
  @Input('data') data: any;
  @Input('category') category: any;
  @Input('Index') Index: any;
  @Input('type_') type_: any;
  @Input('itemData') itemData: any;
  @Input('item_id') item_id: any;
  @Input('reward_id_new') reward_id_new: any;
  @Input('extrasObtained') extrasObtained: any;
  @Input('reward_flag') reward_flag;
  item;
  isActive = true;
  items = [];
  itemsInCart = [];
  favoriteItems = [];
  count: number = 1;
  isFavorite: boolean = false;
  extras = [];
  pickupTime: any;
  longitude: number;
  latitude: number;
  flag: boolean = true;
  noExtras: boolean;
  buttonDisabled: boolean;
  No_of_Free_Extras: any;
  thumbimage: any;
  index: number;
  isexist: boolean = false;
  date: Date;
  time: any;
  day: any;
  instructions: any = '';
  instr: any = '';
  totalCost: any = 0;
  extratotal: any = 0;
  objectPrice: any = 0;
  showInstructions = false;
  myList: any;
  id: any;

  checkstatus: boolean;

  Business_id: any;
  description: any;
  bannerimage: any;

  ItemId: any;
  tagline: any;
  price: number = 0;
  name: any;
  image: any;
  quantity: number = 1;
  myChoices: any = [];
  array_index: any = [];
  checked: boolean;
  options: any[];
  op: any[];
  temp: any[] = new Array();
  checkedItems: boolean[];
  extraChecked: boolean = false;
  item_price: number = 0;
  reward_item_flag: boolean = false;
  reward_id: any;
  type: boolean = true;
  stock_quantity: any;
  itemShowFlag: boolean = false;
  optionitemArray: any;
  upSellItem_array = [];
  price_temp: any;
  extraitemPrice: number = 0;
  requiredExtras: any = [];
  requiredtemp: boolean;
  attributeId: any;
  // itemData: any;
  currentIndex: number = 0;

  businessData: any;
  resAddress: any;
  value: any;
  dateNow: string;
  constructor(
    private toastController: ToastController,
    public modalController: ModalController,
    private router: Router,
    private alertController: AlertController,
    public globals: GlobalVariable,
    public server: UserApiService,
    public loadingCtrl: LoadingController,
    public navCtrl: NavController
  ) {}

  ngOnInit() {}
  ionViewWillEnter() {
    this.reward_item_flag = this.reward_flag;

    if (!this.reward_item_flag) {
      this.reward_item_flag = false;
    }

    if (this.itemData == undefined) {
      //not coming from cart
      let newData = {
        category: this.category,
        item: this.data,
        restaurant: {
          id: this.globals?.businessData?.name,
          name: this.globals?.businessData?.name,
        },
      };

      this.setItemDetail(newData);
      // this.getItemDetails(this.data.id);
    }
    console.log('Dataassa', this.data);
    if (this.itemData != undefined) {
      //coming from cart
      this.getItemDetails(this.itemData.uniqueId);
    }
    this.timingSetting();
    if (this.itemData != undefined) {
      console.log('coming from cart');

      this.currentIndex = this.Index;
      this.quantity = this.itemData.quantity;
      this.globals.itemDetail = this.itemData;
      this.item = this.itemData;
      this.ItemId = this.item.uniqueId;

      this.item_price = this.itemData.totalPrice;
      this.globals.itemDetail.name = this.itemData.menuItem;

      this.instructions = this.itemData.itemInstructions;
      this.extras = this.itemData.menuExtrasSelected;
      console.log('from cart selected extras', this.extras);
      // pending........................
    }
    // ionViewDidEnter(){

    //   }
  }
  showExtras() {
    // this.showInstructions=this.showInstructions=false ? this.showInstructions=true:this.showInstructions=false
    //   console.log( this.showInstructions=false ? this.showInstructions=true:this.showInstructions=false)
    if (this.showInstructions == true) {
      this.showInstructions = false;
    } else {
      this.showInstructions = true;
    }
    console.log('showingggg');
  }

  async addToFavorite(item) {
    if (this.isFavorite == false) {
      this.isFavorite = true;
      if (this.isFavorite == true) {
        let getFavoritesList =
          JSON.parse(localStorage.getItem('favItems')) || [];
        console.log('here checking storage', getFavoritesList);

        //  get list and store new in array
        if (getFavoritesList != null && getFavoritesList.length != 0) {
          //  if cart is not empty push item to itemslist
          item.businessname = this.globals.businessName;
          this.favoriteItems = getFavoritesList;
          this.favoriteItems.push(item);
          localStorage.setItem('favItems', JSON.stringify(this.favoriteItems));
        } else {
          //  first item
          item.businessname = this.globals.businessName;
          this.favoriteItems.push(item);
          localStorage.setItem('favItems', JSON.stringify(this.favoriteItems));
        }

        const toast = await this.toastController.create({
          message: item.name + ' - Added to favorites',
          duration: 2000,
          color: 'dark',
          mode: 'ios',
          // cssClass:'APP_toast'
        });
        toast.present();
      }
    } else {
      this.isFavorite = false;
      this.removeFavorite(item);
      const toast = await this.toastController.create({
        message: 'Removed From Favorites.',
        duration: 2000,
        color: 'dark',
      });
      toast.present();
    }
  }
  removeFavorite(data) {
    this.favoriteItems.forEach((item, index) => {
      if (item === data) this.favoriteItems.splice(index, 1);
      // console.log('removed from fav', item);
      let updatedFavList = localStorage.setItem(
        'favItems',
        JSON.stringify(this.favoriteItems)
      ); //store updated cart to storage
    });
  }

  async cutomizeItem() {
    const modal = await this.modalController.create({
      component: CustomizeItemPage,
      cssClass: 'customize_ItemsModel',
      componentProps: { value: { item: this.item, extras: this.extras } },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      // console.log('extras modal returns', res);
    });
  }
  close() {
    this.modalController.dismiss();
  }
  openCart() {
    this.modalController.dismiss();
    this.router.navigate(['/cart']);
  }

  setItemDetail(res:any){
    console.log('Dataassa', res);
    console.log('Dataassa', this.category);
    // console.log();

    this.globals.isDataLoaded = true;
    console.log('item data form api ', res);
    this.item = res.item;
    this.extras = res.item.extras;

    this.data = res;

    this.id = this.data.item.id;
    // changed.......
    // this.globals.itemDetail=this.data.item
    // ....
    console.log('api response', this.data.item);

    // loading.dismiss();

    this.bannerimage = this.data.item.image;
    this.description = this.data.item.description;
    this.name = this.data.item.name;
    this.image = this.data.item.image;
    this.price = Number(this.data.item.price);
    if (this.itemData != undefined) {
      this.item_price = Number(this.itemData.totalPrice);
      console.log('item price from crt', this.item_price);
    } else {
      this.item_price = this.price;
    }

    this.stock_quantity = this.data.item.stock_quantity;
    this.upSellItem_array = this.data.item.upsellItems;
    this.attributeId = this.data.item.attribute_id;

    if (this.reward_item_flag == true) {
      this.item_price = 0;
    }

    this.No_of_Free_Extras = Number(this.data.item.freeExtras);
    if (this.data.item.extras.length > 0) {
      var noExtras = false;
      // new
      if (this.itemData != undefined) {
        this.extras = this.itemData.menuExtrasSelected;
        console.log('coming from cart');

        this.extrasTotal();

        // this.total_price()
      } else {
        this.extras = this.data.item.extras;
      }
      // new end
      if (this.reward_item_flag == true) {
        this.extras.forEach((extra_price) => {
          if (Number(extra_price.min) > 0) {
            extra_price.options.forEach((ex) => {
              ex.price = 0;
            });
          }
        });
      }

      this.extras.forEach((extra_items) => {
        extra_items.options.forEach((item) => {
          if (item.in_stock) {
            item.disable_item = false;
          } else {
            item.disable_item = false;
          }
        });
      });

      this.extras.forEach((element) => {
        if (element.heading == '') {
          element.options.forEach((element1) => {
            if (element1.name == '') {
              noExtras = true;
            } else {
              noExtras = false;
            }
          });
        }
      });
      this.noExtras = noExtras;
    }
  }

  getItemDetails(id) {
    this.globals.isDataLoaded = false;
    this.server.getItemDetails(id).subscribe(
      (res) => {
        console.log('Dataassa', res);
        console.log('Dataassa', this.category);
        // console.log();

        this.globals.isDataLoaded = true;
        console.log('item data form api ', res);
        this.item = res.item;
        this.extras = res.item.extras;

        this.data = res;

        this.id = this.data.item.id;
        // changed.......
        // this.globals.itemDetail=this.data.item
        // ....
        console.log('api response', this.data.item);

        // loading.dismiss();

        this.bannerimage = this.data.item.image;
        this.description = this.data.item.description;
        this.name = this.data.item.name;
        this.image = this.data.item.image;
        this.price = Number(this.data.item.price);
        if (this.itemData != undefined) {
          this.item_price = Number(this.itemData.totalPrice);
          console.log('item price from crt', this.item_price);
        } else {
          this.item_price = this.price;
        }

        this.stock_quantity = this.data.item.stock_quantity;
        this.upSellItem_array = this.data.item.upsellItems;
        this.attributeId = this.data.item.attribute_id;

        if (this.reward_item_flag == true) {
          this.item_price = 0;
        }

        this.No_of_Free_Extras = Number(this.data.item.freeExtras);
        if (this.data.item.extras.length > 0) {
          var noExtras = false;
          // new
          if (this.itemData != undefined) {
            this.extras = this.itemData.menuExtrasSelected;
            console.log('coming from cart');

            this.extrasTotal();

            // this.total_price()
          } else {
            this.extras = this.data.item.extras;
          }
          // new end
          if (this.reward_item_flag == true) {
            this.extras.forEach((extra_price) => {
              if (Number(extra_price.min) > 0) {
                extra_price.options.forEach((ex) => {
                  ex.price = 0;
                });
              }
            });
          }

          this.extras.forEach((extra_items) => {
            extra_items.options.forEach((item) => {
              if (item.in_stock) {
                item.disable_item = false;
              } else {
                item.disable_item = false;
              }
            });
          });

          this.extras.forEach((element) => {
            if (element.heading == '') {
              element.options.forEach((element1) => {
                if (element1.name == '') {
                  noExtras = true;
                } else {
                  noExtras = false;
                }
              });
            }
          });
          this.noExtras = noExtras;
        }
      },
      (err) => {
        this.globals.presentToast('network Error');
      }
    );
  }
  extrasTotal() {
    console.log('extras counting ');
    this.extraitemPrice = 0;
    this.extras.forEach((items) => {
      items.options.forEach((item) => {
        console.log(item);
        this.extraitemPrice += Number(item.total);
      });
    });
  }
  timingSetting() {
    console.log('checking');
    this.pickupTime = this.globals.myDate;
    let temdate = localStorage.getItem('scheduled_time');
    if (temdate != null || temdate != '' || temdate != undefined) {
      this.pickupTime = temdate;
    }
    if (this.pickupTime != null) {
      if (this.pickupTime.length > 0) {
        this.pickupTime = this.pickupTime.replace('T', ' ');
        this.pickupTime = this.pickupTime.slice(0, 16);
      }
    }

    console.log(this.globals.businessData);

    let long = parseFloat(this.globals.businessData.longitude);
    let lat = parseFloat(this.globals.businessData.latitude);
    this.longitude = long;
    this.latitude = lat;
    console.log('entered in item detail page');

    if (this.globals.kiosk_flag) {
      console.log('kiosk flag  ');
      this.globals.OrderType = 'pickup';
      console.log('Order type is -> ', this.globals.OrderType);
      this.min_date_value();
      // || this.businessData.delivery=="1"
      // if(this.globals.businessData.pickup=="1" ){
      // console.log('pick and delivery check  ')
      // this.globals.OrderType="pickup";

      // // this.globals.myDate= Date.now()
      // // this.presentModal(0);

      // }
    } else {
      this.min_date_value();
    }
  }
  min_date_value() {
    var date = new Date();
    var datenow = date.getDate().toString();
    var month = (date.getMonth() + 1).toString();
    var year = date.getFullYear().toString();

    if (month.length < 2) month = '0' + month;
    if (datenow.length < 2) datenow = '0' + datenow;
    let value = year + '-' + month + '-' + datenow;
    value.toString();

    console.log('value', value);

    var date1 = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
    let datenow1 = date1;
    if (!this.globals.myDate) {
      this.globals.myDate = date1;
      localStorage.setItem('scheduled_time', this.globals.myDate);
    }
  }
  addQuantity() {
    console.log(this.reward_item_flag);
    if (this.reward_item_flag) {
      return;
    }
    if (this.quantity < this.stock_quantity) {
      this.quantity += 1;
      console.log(
        this.quantity,
        'quanti',
        this.extraitemPrice,
        'extras price',
        this.extras
      );

      this.item_price =
        this.extraitemPrice * this.quantity +
        Number(this.price) * this.quantity;
      // this.item_price = this.item_price * this.quantity;
      let tempPrice = this.item_price.toFixed(2);
      this.item_price = Number(tempPrice);
      console.log(this.item_price, this.extraitemPrice, 'bug ');
    } else {
      this.globals.presentToast('You have selected max. limit of item.');
    }
  }

  removeQuantity() {
    if (this.reward_item_flag) {
      return;
    }
    if (this.quantity <= 1) {
      this.quantity = 1;
    } else {
      this.quantity -= 1;
      console.log(this.quantity, 'quanti');
    }

    this.item_price =
      this.extraitemPrice * this.quantity + this.price * this.quantity;
    let tempPrice = this.item_price.toFixed(2);
    this.item_price = Number(tempPrice);
  }

  async add_to_cart_timing_check() {
    // if(this.globals.itemsInCart.length==0){
    console.log('first time adding');
    // this.globals.businessInCartId=this.Business_id
    // console.log('id is ',this.globals.businessInCartId)
    if (this.globals.OrderType == 'delivery') {
      if (this.checkTiming(this.globals.delivery_timing)) {
        console.log('delivery timing is true..... ');
        this.Cart();
      }
    } else {
      if (this.checkTiming(this.globals.pickup_timing)) {
        console.log('pickup timing is true..... ', this.globals.pickup_timing);

        this.Cart();
      }
    }

    // } else if( this.globals.itemsInCart.length>0){
    // console.log('cart has items from' ,this.globals.businessInCartId)
    // if(this.globals.businessInCartId==this.Business_id){
    //   console.log('id matched')
    //   if (this.globals.OrderType == 'delivery') {
    //     if (this.checkTiming(this.globals.delivery_timing)) {
    //       this.Cart();
    //     }
    //   } else {
    //     if (this.checkTiming(this.globals.pickup_timing)) {
    //       this.Cart();
    //     }
    //   }

    // }
    // else{
    //   console.log('Id not matched');
    //   const alert =await this.alertCtrl.create({
    //     message:'You have already selected products from a different restaurant. if you continue,your cart and selection will be removed.',
    //     buttons: [
    //       {
    //           text: 'Cancel',
    //           cssClass: 'cancel_btn',
    //           handler: data => {
    //               console.log('Cancel clicked');
    //           }
    //       },
    //       {
    //           text: 'Clear and Continue',
    //           handler: data => {
    //             this.globals.itemsInCart=[];
    //             // this.globals.businessInCartId=this.Business_id;
    //             if (this.globals.OrderType == 'delivery') {
    //               if (this.checkTiming(this.globals.delivery_timing)) {
    //                 this.Cart();
    //               }
    //             } else {
    //               if (this.checkTiming(this.globals.pickup_timing)) {
    //                 this.Cart();
    //               }
    //             }

    //           }
    //       }
    //   ]
    //   })
    //   await alert.present();

    // }

    // }
  }

  checkTiming(timing) {
    if (this.globals.order_time == 'schedule') {
      console.log('orderType is schedule');
      if (this.globals.specific_delivery_day == 'true') {
        return true;
      }

      var day = this.globals.schedule_day_id + 1;
      if (day == 7) {
        day = 0;
      }

      var time = this.globals.schedule_converted_time;
      var current_day = timing[day];
      var n = current_day[0].indexOf('.');

      if (n != -1) {
        var res = current_day[0].split('.');
        current_day[0] = res[0] + '.' + '3';
      }

      var n1 = current_day[1].indexOf('.');

      if (n1 != -1) {
        var res = current_day[1].split('.');
        current_day[1] = res[0] + '.' + '3';
      }

      if (
        (Number(current_day[0]) <= time && Number(current_day[1]) > time) ||
        (Number(current_day[0]) <= time &&
          Number(current_day[1]) < Number(current_day[0]))
      ) {
        return true;
      } else if (current_day[0] == 'opened' && current_day[1] == 'opened') {
        return true;
      } else {
        this.globals.presentToast(
          'Sorry, we are not serving ' +
            this.globals.OrderType +
            ' at time you schedule!'
        );
        return false;
      }
    } else {
      var date = new Date();
      let day1: any = date.getDay();
      var time: any = date.getHours() + '.' + date.getMinutes();
      time = Number(time);

      var current_day = timing[day1];
      var n = current_day[0].indexOf('.');

      if (n != -1) {
        var res = current_day[0].split('.');
        current_day[0] = res[0] + '.' + '3';
      }

      var n1 = current_day[1].indexOf('.');

      if (n1 != -1) {
        var res = current_day[1].split('.');
        current_day[1] = res[0] + '.' + '3';
      }

      if (
        (Number(current_day[0]) <= time && Number(current_day[1]) > time) ||
        (Number(current_day[0]) <= time &&
          Number(current_day[1]) < Number(current_day[0]))
      ) {
        return true;
      } else if (current_day[0] == 'opened' && current_day[1] == 'opened') {
        return true;
      } else {
        this.globals.presentToast(
          'Sorry, we are not serving ' +
            this.globals.OrderType +
            ' at this time!'
        );
        return false;
      }
    }
  }
  async Cart() {
    // this.globals.activeCartBusinessData=this.businessData;
    // let cartAdditionLoader = await this.loadingCtrl.create({
    //   message: "Adding...",
    // });

    let areAllCategoryCountsFound: boolean = false;
    let totalCategoriesCount: number = 0;
    let categoryCount: number = 0;
    if (this.extras) {
      for (let i = 0; i < this.extras.length; i++) {
        if (this.extras[i].min != '' && Number(this.extras[i].min) > 0) {
          totalCategoriesCount++;
          if (!this.requiredtemp) {
            this.requiredExtras.push(this.extras[i].heading);
          }
        }
      }
      for (let e = 0; e < this.extras.length; e++) {
        // newww
        if (this.itemData != undefined) {
          this.myChoices = this.itemData.menuExtrasSelected;
        }
        // new ends
        for (let c = 0; c < this.myChoices.length; c++) {
          if (this.extras[e].heading == this.myChoices[c].heading) {
            console.log('if');
            if (
              this.extras[e].min != '' &&
              Number(this.extras[e].min) > 0 &&
              this.myChoices[c].options.length >= Number(this.extras[e].min)
            ) {
              console.log('inner if');
              categoryCount++;
              break;
            }
          }
        }
      }
    }

    if (categoryCount == totalCategoriesCount) {
      areAllCategoryCountsFound = true;
    }

    if (areAllCategoryCountsFound) {
      let isMenuItemSame: boolean = false;
      this.globals.itemsInCart.forEach((menuItem) => {
        if (menuItem.uniqueId == this.ItemId) {
          if (this.instructions == '' && menuItem.instructions == '') {
            let menuExtraCategoryLength: any =
              menuItem.menuExtrasSelected.length;
            if (menuExtraCategoryLength == this.myChoices.length) {
              let isMenuItemExtraCategoriesCount: any = 0;
              for (let i = 0; i < menuExtraCategoryLength; i++) {
                for (let j = 0; j < this.myChoices.length; j++) {
                  if (
                    menuItem.menuExtrasSelected[i].heading ==
                    this.myChoices[j].heading
                  ) {
                    let currentExtraCategoryOptionsLength: any =
                      menuItem.menuExtrasSelected[i].options.length;
                    if (
                      currentExtraCategoryOptionsLength ==
                      this.myChoices[j].options.length
                    ) {
                      let isMenuItemExtraCategoriesItemCount: any = 0;
                      for (
                        let k = 0;
                        k < currentExtraCategoryOptionsLength;
                        k++
                      ) {
                        for (
                          let m = 0;
                          m < this.myChoices[j].options.length;
                          m++
                        ) {
                          if (
                            menuItem.menuExtrasSelected[i].options[k].name ==
                              this.myChoices[j].options[m].name &&
                            menuItem.menuExtrasSelected[i].options[k]
                              .quantity == this.myChoices[j].options[m].quantity
                          ) {
                            isMenuItemExtraCategoriesItemCount++;
                            break;
                          }
                        }
                      }
                      if (
                        isMenuItemExtraCategoriesItemCount ==
                        currentExtraCategoryOptionsLength
                      ) {
                        isMenuItemExtraCategoriesCount++;
                        break;
                      }
                    }
                  }
                }
              }
              if (isMenuItemExtraCategoriesCount == menuExtraCategoryLength) {
                isMenuItemSame = true;
              }
            }
          }
        }
      });
      if (isMenuItemSame) {
        for (let i = 0; i < this.globals.itemsInCart.length; i++) {
          if (this.name == this.globals.itemsInCart[i].menuItem) {
            let currentQuantity = this.globals.itemsInCart[i].quantity;
            currentQuantity += this.quantity;
            let current_total = this.quantity * this.price;
            this.globals.itemsInCart[i].quantity = currentQuantity;
            this.globals.itemsInCart[i].totalPrice += current_total;
            break;
          }
        }
        this.close();
      } else {
        this.totalCost = this.quantity * this.price;
        let i = 1;

        for (let sub of this.myChoices) {
          for (let op of sub.options) {
            if (Number(this.No_of_Free_Extras) >= 1) {
              if (op.quantity > 1) {
                let prod_quantity = op.quantity;

                for (let j = prod_quantity; j > 0; j--) {
                  if (this.No_of_Free_Extras >= 1) {
                    prod_quantity = prod_quantity - 1;
                    this.No_of_Free_Extras--;
                    this.extratotal = 0;
                  } else {
                    this.extratotal = this.extratotal + op.price;
                  }
                }

                if (prod_quantity == 0) {
                  op.isFree = true;
                }
                op.quantity = prod_quantity;
              } else {
                this.extratotal = 0;
                op.isFree = true;
                this.No_of_Free_Extras--;
              }
            } else {
              this.extratotal += op.quantity * op.price;
            }
            i = i++;
          }
        }
        this.objectPrice = Number(this.item_price);

        this.objectPrice.toFixed(2);
        this.globals.itemInstruction = this.instructions;
        if (this.reward_item_flag == true) {
          console.log(this.reward_item_flag, 'flag');
          var reward_duplicate = false;
          this.globals.itemsInCart.forEach((menuItem) => {
            console.log(menuItem.reward_id);
            if (menuItem.reward_id == this.reward_id_new) {
              console.log('duplicate check');
              reward_duplicate = true;
            }
          });
          if (!reward_duplicate) {
            console.log('this is working..............');
            this.globals.itemsInCart.push({
              menuId: '1',
              restId: this.globals.bussinessId,
              uniqueId: this.ItemId,
              menuItem: this.name,

              image: this.thumbimage,
              quantity: this.quantity,
              itemInstructions: this.instructions,
              price: this.price,
              totalPrice: this.objectPrice,
              menuExtrasSelected: this.myChoices,
              menuUpsellItemsSelected: [],
              upsell_calculated: false,
              reward: this.reward_item_flag,
              reward_id: this.reward_id_new,
              tax: this.data.item.tax,
              tax_enabled: this.data.item.tax_enabled,
              tax_inclusive: this.data.item.tax_inclusive,
              category_tax: this.data.item.category_tax,
              discount_value: this.data.item.discount_value,
              discount_type: this.data.item.discount_type,
              attributeId: this.attributeId,
            });
            console.log('helllllo4554', this.globals.itemsInCart);
            localStorage.setItem(
              'cart',
              JSON.stringify(this.globals.itemsInCart)
            );

            this.globals.presentToast('Reward added in your cart');
            this.close();
          } else {
            this.globals.presentToast(
              'You have already add this reward in cart.'
            );
            this.close();
          }
        } else {
          if (this.itemData != undefined) {
            console.log('upadting itm in cart>>>>>');
            // this.globals.dataNotLoaded=false;

            // this.globals.Product.push({ menuId: "1", restId: this.globals.bussinessId, uniqueId: this.ItemId, menuItem: this.name, image: this.thumbimage, quantity: this.quantity, itemInstructions: this.instructions, basePrice: this.price, totalPrice: this.objectPrice, menuExtrasSelected: this.myChoices, menuUpsellItemsSelected: [], upsell_calculated: false, reward: this.reward_item_flag, reward_id: this.reward_id, tax: this.data.item.tax, tax_enabled: this.data.item.tax_enabled, discount_value: this.data.item.discount_value, discount_type: this.data.item.discount_type, attributeId:this.attributeId });
            this.globals.itemsInCart.splice(this.currentIndex, 1, {
              menuId: '1',
              restId: this.globals.bussinessId,
              uniqueId: this.ItemId,
              menuItem: this.item.name,
              image: this.thumbimage,
              quantity: this.quantity,
              itemInstructions: this.instructions,
              price: this.price,
              basePrice: this.price,
              totalPrice: this.objectPrice,
              menuExtrasSelected: this.myChoices,
              menuUpsellItemsSelected: [],
              upsell_calculated: false,
              reward: this.reward_item_flag,
              reward_id: this.reward_id,
              tax: this.data.item.tax,
              tax_enabled: this.data.item.tax_enabled,
              tax_inclusive: this.data.item.tax_inclusive,
              category_tax: this.data.item.category_tax,
              discount_value: this.data.item.discount_value,
              discount_type: this.data.item.discount_type,
              attributeId: this.attributeId,
            });
            console.log('helllllo456', this.globals.itemsInCart);
          } else {
            console.log('first time added');
            this.globals.itemsInCart.push({
              menuId: '1',
              restId: this.globals.bussinessId,
              uniqueId: this.data.item.id,
              menuItem: this.name,
              image: this.thumbimage,
              quantity: this.quantity,
              itemInstructions: this.instructions,
              price: this.price,
              totalPrice: this.objectPrice,
              menuExtrasSelected: this.myChoices,
              menuUpsellItemsSelected: [],
              upsell_calculated: false,
              reward: this.reward_item_flag,
              reward_id: this.reward_id,
              tax: this.data.item.tax,
              tax_enabled: this.data.item.tax_enabled,
              tax_inclusive: this.data.item.tax_inclusive,
              discount_value: this.data.item.discount_value,
              category_tax: this.data.item.category_tax,
              discount_type: this.data.item.discount_type,
              attributeId: this.attributeId,
              basePrice: this.price,
            });

            console.log('helllllo456', this.globals.itemsInCart);

            localStorage.setItem(
              'cart',
              JSON.stringify(this.globals.itemsInCart)
            );
          }
        }

        this.close();
        localStorage.removeItem('instructions');
        // if (this.upSellItem_array.length > 0) {
        //   let modal = this.modalCtrl.create('UpSellItemModalPage', { place: this.globals.business_username, itemId: this.ItemId, upSellItems: this.upSellItem_array });
        //   modal.present();
        // } else {

        // }
      }
    } else {
      this.requiredtemp = true;
      this.globals.presentToast(
        'Please select the required extras.' + this.requiredExtras
      );
    }
  }
  async selectedOption(
    heading,
    option,
    freeExtras,
    max,
    event,
    categoryIndex,
    itemIndex
  ) {
    var checked = false;
    let flag: boolean = false;
    if (this.extras[categoryIndex].options[itemIndex].IsSelected == true) {
      this.extras[categoryIndex].options[itemIndex].IsSelected == false;
      option.IsSelected = false;
    } else {
      option.IsSelected = true;
      // this.extras[categoryIndex].options[itemIndex].IsSelected==true;
    }
    // debugger;
    console.log(heading, option, freeExtras, max);
    // option.IsSelected = event.detail.checked;
    // checked=event.detail.checked
    option.price = Number(option.price).toFixed(2);

    console.log('Checking price', option.price);

    if (
      !freeExtras ||
      freeExtras == 'null' ||
      freeExtras == 'undefined' ||
      freeExtras == ''
    ) {
      freeExtras = 0;
    }
    if (this.itemData != undefined) {
      // this.myChoices=this.itemData.menuExtrasSelected;
      // option.IsSelected=true
      // if(option.IsSelected==true){
      //   option.IsSelected=false;
      // }
    }
    if (option.IsSelected) {
      console.log('is checked', option.IsSelected);
      let same: boolean = false;

      for (let m = 0; m < this.myChoices.length; m++) {
        if (this.myChoices[m].heading == heading) {
          same = true;
          // console.log('max extras are   ', max);
          if (max == '') {
            max = 1000;
          }

          if (this.myChoices[m].options.length >= Number(max)) {
            // event.preventDefault();
            option.IsSelected = false;
            // event.detail.checked = false;

            console.log(
              'here...max ',
              this.myChoices[m].options.length >= Number(max)
            );
            this.flag = true;
            console.log(categoryIndex, itemIndex);
            this.extras[categoryIndex].options[itemIndex].IsSelected = false;
            // this.extras[categoryIndex].options[itemIndex].IsSelected=false;
            // this.extras=this.extras;
            // console.log(this.myChoices[m]);
            // this.extras[categoryIndex].options.forEach((itemEx,iEx) => {
            // for(let a=0; a<this.myChoices.length; a++){

            // }
            this.myChoices.forEach((itemss) => {
              if (itemss.heading == this.extras[categoryIndex]) {
                itemss.options.forEach((itemMy, iMy) => {
                  //active items
                  console.log('my choice names.. ', itemMy);
                  this.extras[categoryIndex].options.forEach((itemA, iA) => {
                    //all items of extras
                    if (itemA.name !== itemMy.name) {
                      console.log('not active names', itemA.name);
                      this.extras[categoryIndex].options[iA].IsSelected = false;
                    } else {
                      console.log('active name ', itemMy.name);
                      this.extras[categoryIndex].options[iA].IsSelected = true;
                    }
                  });
                });
              }
            });
            // this.myChoices[m].options.forEach((itemMy,iMy)=>{ //active items
            //   console.log('my choice names.. ',itemMy)
            //   this.extras[categoryIndex].options.forEach((itemA,iA)=>{  //all items of extras
            //     if( itemA.name!==itemMy.name){
            //         console.log('not active names', itemA.name);
            //         this.extras[categoryIndex].options[iA].IsSelected=false;
            //     }
            //     else{
            //       console.log('active name ',itemMy.name)
            //       this.extras[categoryIndex].options[iA].IsSelected=true;

            //     }

            //     })

            // })

            // });
            console.log('total extras ', this.extras[categoryIndex]);

            // option.IsSelected = false;
            // event.detail.checked = false;

            let toast = await this.toastController.create({
              message: 'Oops !! You have added max number of extras',
              duration: 2000,
              position: 'bottom',
              color: 'dark',
            });
            this.flag = false;
            // event.preventDefault();

            await toast.present();
            // debugger
            console.log('end....');
            // return;
            // break;
          } else {
            this.myChoices[m].options.push({
              name: option.name,
              price: option.price,
              quantity: 1,
              total: Number(option.price) * option.quantity,
              isFree: false,
              IsSelected: option.IsSelected,
            });
            this.total_price();
            // this.item_price = (Number(this.item_price) + (Number(op.price) * this.quantity)).toFixed(2);
            console.log('myprice', this.item_price);
            this.flag = false;
            // return;
            // break;
          }
        }
      }
      if (!same) {
        console.log('same check', same);
        if (this.No_of_Free_Extras == 0) {
          var data = {
            heading: heading,
            freeExtras: freeExtras,
            max: max,
            options: [
              {
                name: option.name,
                price: option.price,
                quantity: option.quantity,
                total: Number(option.price) * option.quantity,
                isFree: false,
                IsSelected: option.IsSelected,
              },
            ],
          };

          // this.item_price = (Number(this.item_price) + (Number(op.price) * this.quantity)).toFixed(2);

          this.myChoices.push(data);
          this.total_price();
          this.flag = false;
        } else {
          if (this.myChoices.length < this.No_of_Free_Extras) {
            var data = {
              heading: heading,
              freeExtras: freeExtras,
              max: max,
              options: [
                {
                  name: option.name,
                  price: null,
                  quantity: option.quantity,
                  total: 0,
                  isFree: true,
                  IsSelected: option.IsSelected,
                },
              ],
            };
            this.myChoices.push(data);
            this.flag = false;
          } else {
            var data = {
              heading: heading,
              freeExtras: freeExtras,
              max: max,
              options: [
                {
                  name: option.name,
                  price: option.price,
                  quantity: option.quantity,
                  total: Number(option.price) * option.quantity,
                  isFree: false,
                  IsSelected: option.IsSelected,
                },
              ],
            };

            this.myChoices.push(data);
            this.total_price();
            this.flag = false;
          }
        }
      }
    } else {
      console.log('checked ......', option.IsSelected);
      this.total_price();
      if (this.flag == false) {
        console.log('flag false');
        var value = this.myChoices.findIndex((item, i) => {
          return item.heading === heading;
        });

        if (this.myChoices[value].options.length > 1) {
          console.log('length is greater than 1', this.myChoices);
          this.myChoices[value].options.map((checkitem, i, array) => {
            console.log(checkitem, i, array);

            if (checkitem.name == option.name) {
              // this.extras[value].options[i].IsSelected=false; //new
              console.log('after removing a item my list', this.myChoices);
              array.splice(i, 1);
              this.total_price();
              // this.item_price = (Number(this.item_price) - (Number(op.price) * this.quantity)).toFixed(2);
            }
          });
        } else {
          if (this.myChoices.length > 0) {
            for (let i = 0; i < this.myChoices.length; i++) {
              if (this.myChoices[i].options[0].name == option.name) {
                if (this.myChoices[i].options[0].isFree) {
                } else {
                  this.total_price();
                }
              }
            }
          } else {
          }
          this.myChoices.map((checkitem, i, array) => {
            console.log(checkitem, i, array);

            if (checkitem.heading == heading) {
              array.splice(i, 1);
              this.total_price();
            }
          });
        }
      }

      if (this.flag == true) {
      } else {
      }
    }
  }
  // async selectedOption(heading, option, freeExtras, max, event,categoryIndex,itemIndex) {
  //   var checked = false;
  //   let flag: boolean = false;
  //   if(this.extras[categoryIndex].options[itemIndex].IsSelected==true){
  //     this.extras[categoryIndex].options[itemIndex].IsSelected==false;
  //   option.IsSelected = false;

  //   }
  //   else{
  //   option.IsSelected = true;

  //   }
  //   // debugger;
  //   console.log(heading, option,freeExtras, max);
  //   // option.IsSelected = event.detail.checked;
  //   // checked=event.detail.checked
  //   option.price = Number(option.price).toFixed(2);

  //   console.log('Checking price', option.price);

  //   if (
  //     !freeExtras ||
  //     freeExtras == 'null' ||
  //     freeExtras == 'undefined' ||
  //     freeExtras == ''
  //   ) {
  //     freeExtras = 0;
  //   }
  //   if (this.itemData != undefined) {
  //     // this.myChoices=this.itemData.menuExtrasSelected;
  //     // option.IsSelected=true
  //     // if(option.IsSelected==true){
  //     //   option.IsSelected=false;
  //     // }
  //   }
  //   if (option.IsSelected) {
  //     console.log('is checked', option.IsSelected);
  //     let same: boolean = false;

  //     for (let m = 0; m < this.myChoices.length; m++) {
  //       if (this.myChoices[m].heading == heading) {
  //         same = true;
  //         // console.log('max extras are   ', max);
  //         if (max == '') {
  //           max = 1000;
  //         }

  //         if (this.myChoices[m].options.length >= Number(max)) {
  //           // event.preventDefault();
  //                option.IsSelected = false;
  //           // event.detail.checked = false;

  //           console.log('here...')
  //           this.flag = true;
  //           console.log(categoryIndex,itemIndex);
  //           this.extras[categoryIndex].options[itemIndex].IsSelected=false;
  //           // this.extras[categoryIndex].options[itemIndex].IsSelected=false;
  //           // this.extras=this.extras;
  //           // console.log(this.myChoices[m]);
  //           // this.extras[categoryIndex].options.forEach((itemEx,iEx) => {
  //             // for(let a=0; a<this.myChoices.length; a++){

  //             // }
  //             this.myChoices[m].options.forEach((itemMy,iMy)=>{ //active items
  //               this.extras[categoryIndex].options.forEach((itemA,iA)=>{  //all items of extras
  //                 if( itemA.name!=itemMy.name){
  //                     console.log('not active names', itemA.name);
  //                     this.extras[categoryIndex].options[iA].IsSelected=false;
  //                 }
  //                 else{
  //                   console.log(itemMy.name)
  //                 }

  //                 })

  //             })

  //           // });
  //           console.log('total extras ',this.extras[categoryIndex])

  //           // option.IsSelected = false;
  //           // event.detail.checked = false;

  //           let toast = await this.toastController.create({
  //             message: 'Oops !! You have added max number of extras',
  //             duration: 2000,
  //             position: 'bottom',
  //             color:'dark'
  //           });
  //           this.flag = false;
  //           // event.preventDefault();

  //           await toast.present();
  //           // debugger
  //           console.log('end....')
  //           return;
  //           // break;
  //         } else {
  //           this.myChoices[m].options.push({
  //             name: option.name,
  //             price: option.price,
  //             quantity: 1,
  //             total: Number(option.price) * option.quantity,
  //             isFree: false,
  //             IsSelected: option.IsSelected,
  //           });
  //           this.total_price();
  //           // this.item_price = (Number(this.item_price) + (Number(op.price) * this.quantity)).toFixed(2);
  //           console.log('myprice', this.item_price);
  //           this.flag = false;
  //           return;
  //           // break;
  //         }
  //       }
  //     }
  //     if (!same) {
  //       console.log('same check',same);
  //       if (this.No_of_Free_Extras == 0) {
  //         var data = {
  //           heading: heading,
  //           freeExtras: freeExtras,
  //           max: max,
  //           options: [
  //             {
  //               name: option.name,
  //               price: option.price,
  //               quantity: option.quantity,
  //               total: Number(option.price) * option.quantity,
  //               isFree: false,
  //               IsSelected: option.IsSelected,
  //             },
  //           ],
  //         };

  //         // this.item_price = (Number(this.item_price) + (Number(op.price) * this.quantity)).toFixed(2);

  //         this.myChoices.push(data);
  //         this.total_price();
  //         this.flag = false;
  //       } else {
  //         if (this.myChoices.length < this.No_of_Free_Extras) {
  //           var data = {
  //             heading: heading,
  //             freeExtras: freeExtras,
  //             max: max,
  //             options: [
  //               {
  //                 name: option.name,
  //                 price: null,
  //                 quantity: option.quantity,
  //                 total: 0,
  //                 isFree: true,
  //                 IsSelected: option.IsSelected,
  //               },
  //             ],
  //           };
  //           this.myChoices.push(data);
  //           this.flag = false;
  //         } else {
  //           var data = {
  //             heading: heading,
  //             freeExtras: freeExtras,
  //             max: max,
  //             options: [
  //               {
  //                 name: option.name,
  //                 price: option.price,
  //                 quantity: option.quantity,
  //                 total: Number(option.price) * option.quantity,
  //                 isFree: false,
  //                 IsSelected: option.IsSelected,
  //               },
  //             ],
  //           };

  //           this.myChoices.push(data);
  //           this.total_price();
  //           this.flag = false;
  //         }
  //       }
  //     }
  //   } else {
  //     console.log('checked ......',option.IsSelected);
  //     this.total_price();
  //     if (this.flag == false) {
  //       console.log('flag false');
  //       var value = this.myChoices.findIndex((item, i) => {
  //         return item.heading === heading;
  //       });

  //       if (this.myChoices[value].options.length > 1) {
  //         console.log('length is greater than 1', this.myChoices);
  //         this.myChoices[value].options.map((checkitem, i, array) => {
  //           console.log(checkitem, i, array);

  //           if (checkitem.name == option.name) {
  //             // this.extras[value].options[i].IsSelected=false; //new
  //             console.log('after removing a item my list', this.myChoices);
  //             array.splice(i, 1);
  //             this.total_price();
  //             // this.item_price = (Number(this.item_price) - (Number(op.price) * this.quantity)).toFixed(2);
  //           }
  //         });
  //       } else {
  //         if (this.myChoices.length > 0) {
  //           for (let i = 0; i < this.myChoices.length; i++) {
  //             if (this.myChoices[i].options[0].name == option.name) {
  //               if (this.myChoices[i].options[0].isFree) {
  //               } else {
  //                 this.total_price();
  //               }
  //             }
  //           }
  //         } else {
  //         }
  //         this.myChoices.map((checkitem, i, array) => {
  //           console.log(checkitem, i, array);

  //           if (checkitem.heading == heading) {
  //             array.splice(i, 1);
  //             this.total_price();
  //           }
  //         });
  //       }
  //     }

  //     if (this.flag == true) {
  //     } else {
  //     }
  //   }
  // }
  // async selectedOption(heading, option, freeExtras, max, event) {
  //   // debugger;
  //   console.log(heading, option, freeExtras, max, event);
  //   option.IsSelected = event.detail.checked;
  //   option.price = Number(option.price).toFixed(2);

  //   console.log('Checking price', option.price);
  //   var checked = false;
  //   let flag: boolean = false;

  //   if (
  //     !freeExtras ||
  //     freeExtras == 'null' ||
  //     freeExtras == 'undefined' ||
  //     freeExtras == ''
  //   ) {
  //     freeExtras = 0;
  //   }
  //   if (this.itemData != undefined) {
  //     // this.myChoices=this.itemData.menuExtrasSelected;
  //     // option.IsSelected=true
  //     // if(option.IsSelected==true){
  //     //   option.IsSelected=false;
  //     // }
  //   }
  //   if (option.IsSelected) {
  //     console.log('is checked', option.IsSelected);
  //     let same: boolean = false;

  //     for (let m = 0; m < this.myChoices.length; m++) {
  //       if (this.myChoices[m].heading == heading) {
  //         same = true;
  //         console.log('max extras are   ', max);
  //         if (max == '') {
  //           max = 1000;
  //         }

  //         if (this.myChoices[m].options.length >= Number(max)) {
  //           this.flag = true;
  //           option.IsSelected = false;
  //           event.checked = false;

  //           let toast = await this.toastController.create({
  //             message: 'Oops !! You have added max number of extras',
  //             duration: 2000,
  //             position: 'bottom',
  //             color:'dark'
  //           });
  //           this.flag = false;

  //           await toast.present();
  //         } else {
  //           this.myChoices[m].options.push({
  //             name: option.name,
  //             price: option.price,
  //             quantity: 1,
  //             total: Number(option.price) * option.quantity,
  //             isFree: false,
  //             IsSelected: option.IsSelected,
  //           });
  //           this.total_price();
  //           // this.item_price = (Number(this.item_price) + (Number(op.price) * this.quantity)).toFixed(2);
  //           console.log('myprice', this.item_price);
  //           this.flag = false;
  //           break;
  //         }
  //       }
  //     }
  //     if (!same) {
  //       if (this.No_of_Free_Extras == 0) {
  //         var data = {
  //           heading: heading,
  //           freeExtras: freeExtras,
  //           max: max,
  //           options: [
  //             {
  //               name: option.name,
  //               price: option.price,
  //               quantity: option.quantity,
  //               total: Number(option.price) * option.quantity,
  //               isFree: false,
  //               IsSelected: option.IsSelected,
  //             },
  //           ],
  //         };

  //         // this.item_price = (Number(this.item_price) + (Number(op.price) * this.quantity)).toFixed(2);

  //         this.myChoices.push(data);
  //         this.total_price();
  //         this.flag = false;
  //       } else {
  //         if (this.myChoices.length < this.No_of_Free_Extras) {
  //           var data = {
  //             heading: heading,
  //             freeExtras: freeExtras,
  //             max: max,
  //             options: [
  //               {
  //                 name: option.name,
  //                 price: null,
  //                 quantity: option.quantity,
  //                 total: 0,
  //                 isFree: true,
  //                 IsSelected: option.IsSelected,
  //               },
  //             ],
  //           };
  //           this.myChoices.push(data);
  //           this.flag = false;
  //         } else {
  //           var data = {
  //             heading: heading,
  //             freeExtras: freeExtras,
  //             max: max,
  //             options: [
  //               {
  //                 name: option.name,
  //                 price: option.price,
  //                 quantity: option.quantity,
  //                 total: Number(option.price) * option.quantity,
  //                 isFree: false,
  //                 IsSelected: option.IsSelected,
  //               },
  //             ],
  //           };

  //           this.myChoices.push(data);
  //           this.total_price();
  //           this.flag = false;
  //         }
  //       }
  //     }
  //   } else {
  //     console.log('Not working......');
  //     this.total_price();
  //     if (this.flag == false) {
  //       console.log('flag false');
  //       var value = this.myChoices.findIndex((item, i) => {
  //         return item.heading === heading;
  //       });

  //       if (this.myChoices[value].options.length > 1) {
  //         console.log('length is greater than 1', this.myChoices);
  //         this.myChoices[value].options.map((checkitem, i, array) => {
  //           console.log(checkitem, i, array);

  //           if (checkitem.name == option.name) {
  //             console.log('after removing a item my list', this.myChoices);
  //             array.splice(i, 1);
  //             this.total_price();
  //             // this.item_price = (Number(this.item_price) - (Number(op.price) * this.quantity)).toFixed(2);
  //           }
  //         });
  //       } else {
  //         if (this.myChoices.length > 0) {
  //           for (let i = 0; i < this.myChoices.length; i++) {
  //             if (this.myChoices[i].options[0].name == option.name) {
  //               if (this.myChoices[i].options[0].isFree) {
  //               } else {
  //                 this.total_price();
  //               }
  //             }
  //           }
  //         } else {
  //         }
  //         this.myChoices.map((checkitem, i, array) => {
  //           console.log(checkitem, i, array);

  //           if (checkitem.heading == heading) {
  //             array.splice(i, 1);
  //             this.total_price();
  //           }
  //         });
  //       }
  //     }

  //     if (this.flag == true) {
  //     } else {
  //     }
  //   }
  // }

  total_price() {
    this.extraitemPrice = 0;
    console.log('item price counting,item  ', this.globals.itemDetail);
    this.item_price = this.globals.itemDetail.price * this.quantity;
    console.log('total amount', this.item_price);
    for (let c = 0; c < this.myChoices.length; c++) {
      for (
        let b = this.myChoices[c].freeExtras;
        b < this.myChoices[c].options.length;
        b++
      ) {
        if (this.itemData != undefined) {
          this.extraitemPrice =
            this.extraitemPrice + Number(this.myChoices[c].options[b].price);
          this.item_price =
            this.item_price +
            this.myChoices[c].options[b].price * this.quantity;
        } else {
          console.log('here');
          this.extraitemPrice =
            this.extraitemPrice + Number(this.myChoices[c].options[b].price);
          console.log(this.extraitemPrice);
          this.item_price =
            this.item_price +
            this.myChoices[c].options[b].price * this.quantity;
        }
        // this.extraitemPrice
        // this.extraitemPrice = this.extraitemPrice+this.myChoices[c].options[b].price;
        // this.item_price = (Number(this.item_price) + (Number(this.myChoices[c].options[b].price) * this.quantity)).toFixed(2);
        console.log(
          'total amount with extras',
          this.item_price,
          this.extraitemPrice
        );
      }
    }
  }
}
