import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/global';
import { HoldOrderMenuPage } from 'src/app/hold-order-menu/hold-order-menu.page';
import { UserApiService } from 'src/app/services/user-api.service';

@Component({
  selector: 'hold-orders',
  templateUrl: './hold-order.component.html',
  styleUrls: ['./hold-order.component.scss'],
})
export class HoldOrderComponent implements OnInit {

  constructor(private modalController:ModalController,private server:UserApiService,public globals:GlobalVariable) { }

  ngOnInit() {
    this.gellAllHoldOrders();
    let that=this
    // setInterval(() => {
    //   that.gellAllHoldOrders();
      
    // }, 5000);
  }
  async openHoldOrderMenu(){
   
      const modal = await this.modalController.create({
      component: HoldOrderMenuPage,
      componentProps: { value: 123 },
      // breakpoints:[0,0.3,0.5,0.7,0.8,1],
      // initialBreakpoint:0.7,
      cssClass:'dine_inModal',
      mode:'ios',
      handle:false,


      });
    
      await modal.present();
    
      const data = await modal.onDidDismiss();
      console.log(data)
    
    
  }
  gellAllHoldOrders(){
    this.server.getHoldOrders().subscribe(res=>{
      console.log(res)
      if(res.success){
        this.globals.holdOrdersLength=res.data.length;
        this.globals.holdOrders=res.data;
      }else{
        this.globals.holdOrders=[]
        this.globals.holdOrdersLength=0;
      }
    })
  }
}
