import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private lastActivityTime: number;
  private readonly inactivityThreshold = 60000; // 10 seconds

  private userActiveSubject = new BehaviorSubject<boolean>(true);
  private orderSplash = new BehaviorSubject<boolean>(true);

  userActive$: Observable<boolean> = this.userActiveSubject.asObservable();

  constructor() {
    this.updateLastActivityTime();
    this.initInactivityCheck();
  }

  updateLastActivityTime() {
    this.lastActivityTime = Date.now();
  }

  private initInactivityCheck() {
    timer(0, 1000).subscribe(() => {
      const now = Date.now();

      if (now - this.lastActivityTime > this.inactivityThreshold) {
        this.userActiveSubject.next(false);
      }
    });
  }

  setUserActive() {
    this.updateLastActivityTime();
    this.userActiveSubject.next(true);
  }

  setOrderSplash(data: any) {
    this.orderSplash.next(data);
  }

  getSPlashScreenVal() {
    return this.orderSplash.asObservable();
  }
}
