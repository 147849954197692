import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, Platform } from '@ionic/angular';

import { GlobalVariable } from '../global';
import { GuestLoginPage } from '../guest-login/guest-login.page';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.page.html',
  styleUrls: ['./login-modal.page.scss'],
})
export class LoginModalPage implements OnInit {
@Input()data: any
  constructor(
    private router:Router,
    public server:UserApiService,
    public globals:GlobalVariable,
    private navCTRL:NavController,
    public modalCtrl:ModalController,
    public platform:Platform,

  ) {
    console.log('here in const')
   }

  ngOnInit() {
    console.log('here in oninit')

  }
  register(){
    if(this.data?.isFromCart){
      this.globals.takeMeToCart=true
    }
  this.modalCtrl.dismiss();

    // this.router.navigateByUrl('/sign-up');
    this.navCTRL.navigateRoot('/sign-up');

  }
  ionViewWillEnter(){
    console.log("testing",this.globals)
  }
  async guestLogin(){
    // let modal=await this.globals.presentToast("Coming soon");
  this.modalCtrl.dismiss();
  let modal=await this.modalCtrl.create({
    component:GuestLoginPage,
    mode:'ios',
    cssClass:'guestModal',

  })
  modal.present();
    
  }
  loginwithEmail(){
    if(this.data?.isFromCart){
      this.globals.takeMeToCart=true
    }
    this.navCTRL.navigateRoot('/login')

  this.modalCtrl.dismiss();

  }
  openPrivacy(){
    this.router.navigate(['privacy-policy'])
  this.modalCtrl.dismiss();

    // this.modal
  }
  terms(){
    this.router.navigate(['terms-conditions'])
  this.modalCtrl.dismiss();

    // this.modal
  }
  close(){
    console.log('login modal')
    this.modalCtrl.dismiss({dismiss:'true'});
  }
}
