import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {ResModelComponent} from '../components/res-model/res-model.component'
import { CartComponent } from './cart/cart.component';
import { HoldOrderComponent } from './hold-order/hold-order.component';

@NgModule({
  declarations: [ResModelComponent,CartComponent,HoldOrderComponent],
  imports: [
    CommonModule,
    IonicModule,
    // ResModelComponent
  ],
  exports: [ResModelComponent,CartComponent,HoldOrderComponent]
})
export class ComponentsModule { }
