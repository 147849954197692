import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';
import Swiper from 'swiper';
@Component({
  selector: 'app-game-launcher',
  templateUrl: './game-launcher.page.html',
  styleUrls: ['./game-launcher.page.scss'],
})
export class GameLauncherPage implements OnInit , AfterViewInit {
 
  constructor(private modal:ModalController,
    public swiper:Swiper,
    public globals:GlobalVariable) { }

  ngOnInit() {
  }
  ngAfterViewInit(){
    this.swiper = new Swiper('.swiper-containerHor', {
      // Swiper.js options here
      slidesPerView: this.getSlidesPerView(),
    // autoplay: true,
    loop: true,
    // speed: 300,
    // autoplayDisableOnInteraction: false,
    noSwiping: true,
    spaceBetween: 10,
  
    });
  }
  getSlidesPerView(): number {
  
    const screenWidth = window.innerWidth;
    if (screenWidth < 576) {
      return 1.6;
    } else if (screenWidth < 768) {
      return 2.2;
    } else if (screenWidth < 992) {
      return 3.2;
    } else {
      return 4.2;
    }
  }

  close(){
    this.modal.dismiss();
  }
  gameInfo(){
    this.globals.presentToast('game info')
  }
}
