import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController } from '@ionic/angular';
import { AllHoldOrdersPage } from '../all-hold-orders/all-hold-orders.page';
import { GameLauncherPage } from '../game-launcher/game-launcher.page';
import { GlobalVariable } from '../global';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-hold-order-menu',
  templateUrl: './hold-order-menu.page.html',
  styleUrls: ['./hold-order-menu.page.scss'],
})
export class HoldOrderMenuPage implements OnInit {
  callType: any;
  activeId
  toggleCustomCheck = false;
  msg
  constructor(private modalController: ModalController,private aib:InAppBrowser, private router: Router, public globals: GlobalVariable, private server: UserApiService) { }

  ngOnInit() {
  }
  close() {
    this.modalController.dismiss()
  }
  toggleCustom() {
    if (this.toggleCustomCheck) {
      this.toggleCustomCheck = false

    }
    else {
      this.toggleCustomCheck = true;
      window.scroll(0, document.body.scrollHeight);
      //  let modal= this.modalController.getTop();
    }

  }
  call(type) {
    this.callType = type;
    console.log(this.callType);
    let callMsg;
    if (type == 'call_waiter') {
      callMsg = 'Send waiter to table : ' + this.globals.dineInTable;
    }
    else if (type == 'bring_check') {
      callMsg = "Please Bring Check : " + this.globals.dineInTable;
    }
    else if(type=='pay_now'){
      this.payNow();
      return;
    }
    else if(type=='play_games'){
      this.globals.presentToast('Coming soon..');
      // this.gamelauncher()
      return;
    }
    else {
      this.callType = 'custom';
      callMsg = this.msg

    }
    this.globals.loader();
    this.server.dineInCalltoPOS(this.callType, callMsg).subscribe(res => {
      this.globals.dismiss();
      console.log(res)
      if (res.success) {
        this.globals.presentToast(res.message);
        this.modalController.dismiss();
      }
      else {
        this.globals.presentToast(res.message);
      }
    }, err => {
      this.globals.dismiss()
      this.globals.presentToast("Network Error");


    })
  }
  async openHoldOrders() {
    // this.router.navigateByUrl('all-hold-orders');
    // this.modalController.dismiss();

    const modal = await this.modalController.create({
      component: AllHoldOrdersPage,
      componentProps: { value: 123 },
      cssClass: 'holdOrder_modal'
    });

    await modal.present();

    const data = await modal.onDidDismiss();
    console.log(data)


  }
  payNow(){
   
    let hold_id= this.globals.holdOrders[0].hold_id
    let url="https://staging.onlineordering.mikronexus.com/index.php/pay_invoice/"+hold_id+'-'+this.globals.businessData.business_id;
    console.log(url)
    this.aib.create(url,'_blank');
    this.modalController.dismiss();
  }
  async gamelauncher(){
    const modal = await this.modalController.create({
      component: GameLauncherPage,
      componentProps: { value: 123 },
      cssClass: 'dine_inModal'
    });

    await modal.present();

    const data = await modal.onDidDismiss();
    console.log(data)
  }
}
