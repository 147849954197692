import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-res-model',
  templateUrl: './res-model.component.html',
  styleUrls: ['./res-model.component.scss'],
})
export class ResModelComponent implements OnInit {
  //name:string
  @Input('name') name;
  @Input('logo') logo;
  @Input('ratings') ratings;
  @Input('delivery') delivery;
  @Input('items') items;
  @Input('price') price;
 


  constructor() { }

  ngOnInit() {}
  floated(x) {
    return Number.parseFloat(x).toFixed(1);
  }

}
