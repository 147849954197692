import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/global';
import { ItemPreviewPage } from 'src/app/item-preview/item-preview.page';

@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

@Input('editable')editable;
@Input('paymentPage')paymentPage;
@Input('dataFromPayment')dataFromPayment;
@Output() sendCartInfo: EventEmitter<string> = new EventEmitter<string>();
    RewardStoreCreditAvailed: any = 0;
    birthdayStoreCreditavailed: any = 0;
    birthdayStoreCreditInput: number;
    availed_points: number;
    tax_calc: any;
    selectOptions: { title: string };
    point_rewards: any;
    pointsAvailed: boolean = false;
    points: any;
    pointExists: boolean;
    mobileDiscountFlag: any;
    deliverycharges: number;
    ProductsTotal: any;
    firsattempt: boolean = true;
    Deliver: boolean;
    pickup: boolean = false;
    birthday_amount: number = 0;
    birthdayCreditExist: any;
    BirthdayDiscount: any;
    pointsInput: any;
    StoreCreditInput: number;
    reward_amount: number = 0;
    storecreditExist: boolean = false;
    discountTotal: number = 0;
    updatedTotal: number;
  
    extratotal: any = 0;
    data: any;
    Total: any = 0;
    subtotal: any = 0;
    image: any;
    price: any;
    Quantity: any;
    productName: any;
    extraSum = new Array();
    storeCredit: boolean = false;
    Tip: any = 0;
    notes: any;
    check_rewards: boolean = false;
    check_points: boolean = false;
    user_availed_points: boolean = false;
    check_birthday: boolean = false;
    type: any;
    instructionsItem: any;
    instruct: Boolean = true;
    Address: any;
    point_show: any = 0;
    per_tip: any = 0;
    tip_cus: any = 0;
    gift_card_amount = 0;
    mygifts = [];
    gift_array = [];
    delivery_display: any;
    tip_type: any = 'percent';
    percent_tip_vlaue: any;
    ccFee: any = 0;
    tax: any;
  
    utensils_array = [
      {
        type: 'Yes',
        selected: false,
      },
      {
        type: 'No',
        selected: true,
      },
    ];
    utensils_note: any = '';
    pickupTime: string;
    currentLength: number = 0;
    constructor(public globals: GlobalVariable,
      public modalController:ModalController,

      ) {
       
      // console.log('constru',this.paymentPage)
      // setInterval(() => {
        
      //   if (this.globals.itemsInCart.length > this.currentLength) {
      //     console.log('length is working',this.currentLength,this.globals.itemsInCart.length);
      //     this.total();
      //     this.currentLength = this.globals.itemsInCart.length;
      //     console.log('new length is ',this.currentLength);

        
      //   }
      //   else{
      //     console.log('length is  not working',that.currentLength);

      //   }
      // }, 1000);
    }
  
    ngOnInit() {
      // console.log('constru',this.paymentPage)
      if(this.paymentPage){
        console.log(this.paymentPage)
        this.total();
      }
      else{
        console.log('no payment')
      }
      
    }
  
    ionViewWillLeave(){
      this.point_show=0;
      this.globals.points_availed=0;
      // this.total();
    }
    RemoveItem(product) {
      if(this.paymentPage){
        console.log(this.paymentPage)
        return;
      }
      console.log(product);
  
      if (this.globals.itemsInCart.length == 1) {
        this.globals.BusinessID = -1;
        this.globals.cartflag = false;
        this.ProductsTotal = Number(this.ProductsTotal) - product.totalPrice;
        this.Total = this.Total + this.ProductsTotal;
        // this.globals.BusinessDiscountFlag = false;
  
        var index = this.globals.itemsInCart.indexOf(product);
        this.globals.itemsInCart.splice(index, 1);
        // this.setArray();
        this.globals.retail_items_discount = 0;
        this.sendDataToOrderPage()
        // this.navCtrl.popTo('CategoryPage');
        // this.navCtrl.navigateBack('business-details/' + this.globals.activeBusinessData.business_id);
      } else {
        //  console.log("data",product.basePrice*product.quantity,product.basePrice,product.quantity)
        var index = this.globals.itemsInCart.indexOf(product);
        this.globals.itemsInCart.splice(index, 1);
        this.total();
        this.sendDataToOrderPage()
  
        console.log('subtract', this.Total);
  
        // this.setArray();
      }

    }
    async detail(menuitem, i) {
      if(this.paymentPage){
        console.log(this.paymentPage)
        return;
      }
      console.log(menuitem.uniqueId, 'id');
      console.log('Index in cart of this item is ', i);
      console.log('data sending from cart to itempage', menuitem, i);
      this.globals.businessMenuItem = menuitem;
  
      const modal = await this.modalController.create({
        component: ItemPreviewPage,
        componentProps: {
          itemData: menuitem,
          Index: i,
          type_: true,
          item_id: menuitem.uniqueId,
        },
      });
  
      await modal.present();
  
      modal.onDidDismiss().then(res=>{
        this.total();
        this.sendDataToOrderPage()
      })
     
      // this.navCtrl.push('ItemDetailPage', {Index:i, type: true, item_id: item.uniqueId, BusinesId: this.globals.BusinessID ,itemData:item})
    }
  
    total() {
      this.Total = 0;
     console.log('payment page data ',this.dataFromPayment)

      var amount_without_tax = 0;
      // console.log(this.globals.deliveryCharges, "delivery cahrges");
      this.globals.deliveryCharges =
        Math.round(this.globals.deliveryCharges * 100) / 100;
      console.log(this.globals.deliveryCharges, 'delivery charges');
      for (let sub of this.globals.itemsInCart) {
        this.Total = Number(this.Total);
        sub.totalPrice = Number(sub.totalPrice);
        console.log('iten price without tax ', sub.totalPrice);
        this.Total += Number(sub.totalPrice);
        console.log('prices are', this.Total);
        if (sub.tax_enabled == 'false' || sub.tax_enabled == false) {
          amount_without_tax += Number(sub.totalPrice);
        }
        this.ProductsTotal = this.Total;
      }
      // debugger;
      //if discount is availble
      if (
        this.globals.BusinessDiscount > 0 &&
        this.globals.availed_discount_count < this.globals.business_discount_count
      ) {
        console.log('Discount available........');
        this.discountTotal =
          (Number(this.ProductsTotal) * this.globals.BusinessDiscount) / 100;
        this.discountTotal = Math.round(this.discountTotal * 100) / 100;
        // this.discountTotal.toFixed(2);
        this.Total = this.ProductsTotal - this.discountTotal;
        // this.Total = this.Total.toFixed(2);
        this.globals.BusinessDiscountFlag = true;
      }
  
      if (
        (Number(this.Total) < this.globals.minimun_order ||
          Number(this.Total) < 0) &&
        this.Deliver == true
      ) {
        this.TaxCalculate(amount_without_tax);
        if (Number(this.ProductsTotal) == 0) {
          this.TaxCalculate(amount_without_tax);
          if (this.RewardStoreCreditAvailed > 0) {
            this.Total = Number(this.Total) - this.RewardStoreCreditAvailed;
          }
          if (this.birthdayStoreCreditavailed > 0) {
            this.Total = Number(this.Total) - this.birthdayStoreCreditavailed;
          }
        } else {
          this.globals.presentToast('Please add more items in the cart.');
        }
      } else {
        this.TaxCalculate(amount_without_tax);
        if (this.RewardStoreCreditAvailed > 0) {
          this.Total = Number(this.Total) - this.RewardStoreCreditAvailed;
        }
        if (this.birthdayStoreCreditavailed > 0) {
          this.Total = Number(this.Total) - this.birthdayStoreCreditavailed;
        }
      }
  
      if (
        this.globals.OrderType == 'delivery' &&
        this.globals.deliveryCharges != 0 &&
        this.Deliver == true
      ) {
        console.log('adding delivery charges...........');
        this.Total = Number(this.Total);
        this.Total = this.Total + Number(this.globals.deliveryCharges);
        //    this.deliverycharges = Number(this.globals.deliveryCharges);
      }
     if(this.paymentPage){
      this.Tip=this.dataFromPayment.Tip;
          this.Total = Number(this.Total) + Number(this.Tip)


     }
  
      // if (Number(this.Tip > 0) && this.tip_type == 'manual') {
      //     this.Total = Number(this.Total) + Number(this.Tip);
      // }
      // else if (this.tip_type == 'percent') {
      //     this.percent_tip_(this.percent_tip_vlaue);
      // }
      if(this.paymentPage){
      if (this.dataFromPayment?.giftcard.length > 0) {
          this.dataFromPayment.giftcard.forEach(e => {
              this.Total = Number(this.Total) - Number(e.amount)
  
          });
      }
    }
  
      this.Total = Number(this.Total);
  
      //if cash discount
      if (this.globals.cash_discount_enabled && this.globals.card_enabled) {
        // this.ccFee = (
        //   (Number(this.globals.cash_discount_percentage) / 100) *
        //   Number(this.Total)
        // ).toFixed(2);
        // this.ccFee =
        //   Number(this.ccFee) + Number(this.globals.cash_discount_value);
       
         
    
    
         
        // this.ccFee = this.dataFromPayment.ccFee
        if(this.paymentPage){
          this.ccFee = this.globals.paymentPageData.ccFee;
          this.Total = this.Total + Number(this.ccFee);
          console.log('total with ccFee ', this.Total);

// if(this.dataFromPayment.coupon_amount){
//   this.point_show=this.dataFromPayment.coupon_amount;



//           if(this.point_show>0){
//             this.Total=this.Total-this.point_show;
            
//           }
//           }
if(this.dataFromPayment.Points){
  console.log('here........... points comp');
  
  this.point_show=Number(this.dataFromPayment.Points);



          // if(this.point_show>0){
          //   // this.Total=this.Total-this.point_show;
            
          // }

          }
      
  
      }
      if (this.globals.couponAmount != 0 && this.globals.couponAmount != NaN) {
        this.Total = this.Total - this.globals.couponAmount; //15-20 = -5     20 -5 =15 is actual used amount
        if (this.Total < 0) {
          this.globals.couponAmount = this.globals.couponAmount + this.Total; // +- = -
          console.log(
            this.globals.couponAmount,
            'coupon after total less then 0'
          );
          this.Total = 0;
        }
      }
      if(this.globals.subsidy>0){
        this.Total=this.Total- this.globals.subsidy;
        if (this.Total < 0) {
          this.globals.subsidy = this.globals.subsidy + this.Total; // +- = -
          console.log(
            this.globals.subsidy,
            'subsidy after total less then 0'
          );
          this.Total = 0;
        }
      }
      if(this.dataFromPayment.platformFeeEnabled){
        this.Total= this.Total+ Number(this.dataFromPayment.platformFeeAmount)
      }
      if(this.dataFromPayment.giftcard!='' && this.dataFromPayment?.giftcard[0].amount>0){
        this.gift_card_amount=this.dataFromPayment .giftcard[0].amount
        // this.Total=this.Total- Number(this.dataFromPayment .giftcard[0].amount);
        // if (this.Total < 0) {
        //   this.dataFromPayment .giftcard[0].amount = this.dataFromPayment .giftcard[0].amount + this.Total; // +- = -
        //   console.log(
        //     this.dataFromPayment .giftcard[0].amount,
        //     'gift amount after total less then 0'
        //   );
        //   this.Total = 0;
        // }
       
  
      }
    }
    
  
      if (this.Total != 0) {
        this.Total = Number(this.Total).toFixed(2);
      }
    }
  
  
    TaxCalculate(amount_without_tax) {
      // if (
      //   this.globals.business_type == 'retail' ||
      //   this.globals.business_type == 'salon'
      // ) {
      //   console.log('type retail and salon..should not work in app');
      //   var taxcalc = 0;
      //   this.globals.retail_items_discount = 0;
      //   var ItemDiscount = 0;
      //   var ItemDiscountedValue = 0;
      //   for (let sub of this.globals.itemsInCart) {
      //     ItemDiscount = 0;
      //     let CalculatedTax = 0;
      //     sub.discount_type == 'cash'
      //       ? (ItemDiscount += Number(sub.discount_value) * Number(sub.quantity))
      //       : sub.discount_type == 'perc'
      //       ? (ItemDiscount +=
      //           (Number(sub.discount_value) / 100) *
      //           Number(sub.basePrice) *
      //           Number(sub.quantity))
      //       : console.log('discount neither cash nor percent');
  
      //     ItemDiscountedValue =
      //       Number(sub.basePrice) * Number(sub.quantity) - Number(ItemDiscount);
      //     CalculatedTax = (Number(sub.tax) / 100) * Number(ItemDiscountedValue);
      //     taxcalc += CalculatedTax;
      //     this.globals.retail_items_discount += ItemDiscount;
      //     sub.CalculatedDiscount = this.financial(ItemDiscount);
      //     sub.CalculatedTax = this.financial(CalculatedTax);
      //   }
      //   this.tax_calc = taxcalc.toFixed(2);
      //   console.log('tax is ', this.tax_calc);
      //   this.Total = this.Total + taxcalc - this.globals.retail_items_discount;
      //   this.Total = this.Total.toFixed(2);
      //   console.log('amount with tax', this.Total);
      // } else {
      //   console.log('calculate tax for app');
      //   var tax_amount =
      //     Number(this.ProductsTotal) -
      //     (Number(amount_without_tax) + Number(this.discountTotal));
  
      //   var taxcalc = (Number(this.globals.tax) / 100) * tax_amount;
      //   console.log(
      //     'amounts are :discount: ',
      //     this.discountTotal,
      //     ' ProductsTotal: ',
      //     this.ProductsTotal,
      //     ' TaxCalc: ',
      //     taxcalc,
      //     ' Tax amount: ',
      //     tax_amount,
      //     ' amount withouttax: ',
      //     amount_without_tax,
      //     ' Total: ',
      //     this.Total
      //   );
      //   this.tax = Number(taxcalc.toFixed(3));
      //   this.tax_calc = this.tax.toFixed(2);
      //   console.log('tax is ', this.tax_calc);
      //   this.Total = this.Total + taxcalc;
  
      //   this.Total = this.Total.toFixed(2);
      //   console.log('amount with tax', this.Total);

      // }


       if (
      this.globals.business_type == 'retail' ||
      this.globals.business_type == 'salon'
    ) {
      console.log('type retail and salon..should not work in app');
      var taxcalc = 0;
      this.globals.retail_items_discount = 0;
      var ItemDiscount = 0;
      var ItemDiscountedValue = 0;
      for (let item of this.globals.itemsInCart) {
        ItemDiscount = 0;
        let CalculatedTax = 0;
        item.discount_type == 'cash'
          ? (ItemDiscount +=
              Number(item.discount_value) * Number(item.quantity))
          : item.discount_type == 'perc'
          ? (ItemDiscount +=
              (Number(item.discount_value) / 100) *
              Number(item.basePrice) *
              Number(item.quantity))
          : console.log('discount neither cash nor percent');

        ItemDiscountedValue =
          Number(item.basePrice) * Number(item.quantity) - Number(ItemDiscount);
        CalculatedTax = (Number(item.tax) / 100) * Number(ItemDiscountedValue);
        taxcalc += CalculatedTax;
        this.globals.retail_items_discount += ItemDiscount;
        item.CalculatedDiscount = this.globals.financial(ItemDiscount);
        item.CalculatedTax = this.globals.financial(CalculatedTax);
      }

      this.tax_calc = taxcalc.toFixed(2);
      console.log('tax is ', this.tax_calc);
      this.Total = this.Total + taxcalc - this.globals.retail_items_discount;
      this.Total = this.Total.toFixed(2);
      console.log('amount with tax', this.Total);
    } else {
      //for app and web
      console.log('calculate tax for app');

      var taxcalc = 0;
      this.globals.itemsInCart.forEach((item) => {
        console.log(item);
        console.log('item with tax ', item);

        if (item.tax_enabled == 'true' || item.tax_enabled == true) {
          //item tax enabled
          taxcalc =
            Number(taxcalc) +
            (Number(item?.category_tax ? item.category_tax : 0) / 100) *
              Number(item.totalPrice);
          console.log('item with tax ', item.menuItem, taxcalc);
        } else {
          //item tax disabled using global tax          
          if (item.tax_inclusive == 1) {

             taxcalc =
               Number(taxcalc) +
               (Number(0) / 100) *
                 Number(item.totalPrice);
          } else {
        

             taxcalc =
               Number(taxcalc) +
               (Number(this.globals.tax ? this.globals.tax : 0) / 100) *
                 Number(item.totalPrice);
          }
         
          console.log('item with global tax ', this.globals.tax, taxcalc);
        }
      });
      this.tax = Number(taxcalc.toFixed(3));
      this.tax_calc = this.tax.toFixed(2);
      console.log('total tax is ', this.tax_calc);
      this.Total = Number(this.Total) + Number(taxcalc);

      this.Total = this.Total.toFixed(2);
 
      //console.log(this.Total);
    }

  }
    financial(x) {
      if (x != 0) {
        return Number.parseFloat(x).toFixed(2);
      } else {
        return '0.00';
      }
    }
    sendDataToOrderPage() {
      this.sendCartInfo.emit(this.Total);
    }
}
